@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 1001 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.my-swal {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(2px);
}

body.swal2-shown > [aria-hidden="true"] {
  transition: 0.1s filter;
  filter: blur(3px);
}
body.swal2-container,
body.swal2-center,
body.swal2-backdrop-show {
  background-color: rgba(0, 0, 0, 0.5) !important;
  opacity: 5 !important;
  transition: opacity 0.2s ease !important;
}
.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
}
body.swal2-height-auth {
  height: 240px !important;
}

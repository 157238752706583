.home-one .hero-section {
    /* background-image: linear-gradient(to right, #2272FF, #0D03C3); */
    background-image: linear-gradient(-45deg, #0DACB6, #1F168E, #06479E);
    position: relative;
}

.bg-rectangle {
    position: absolute;
    background: url("../../Assets/bg-rectangle.png") no-repeat bottom right;
    background-size: cover;
    inset: 0;
}

.home-one .about {
    background-color: #EAFEFF;
}

.home-one .services {
    background-color: #0F0B30;
}

.home-one .testimony {
    background-color: #0D2A80;
}

.home-one footer {
    background-image: linear-gradient(to right, #2272FF, #0D03C3);
}

.home-one .bg-gradient {
    background-image: linear-gradient(45deg, red, yellow, green);
}

.gradient-text {
    /* Set the background color */
    background: linear-gradient(to right, #00F0FF 0%, #5773FF 100%);
    /* Mask the color to the text, and remove the rest  */
    background-clip: text;
    /* Make the text fill color value transparent so the masked background color comes through */
    -webkit-text-fill-color: transparent;
}

.gap-1 {
    gap: 8px;
}

.gap-2 {
    gap: 16px;
}

.gap-3 {
    gap: 24px;
}

.gap-4 {
    gap: 32px;
}

.w-fit {
    width: fit-content;
}

.text-xl {
    font-size: 32px;
}

.text-2xl {
    font-size: 40px;
}

.text-3xl {
    font-size: 48px;
}

.text-4xl {
    font-size: 56px;
}